import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Divider, Row, Table } from 'antd'
import * as XLSX from 'xlsx'
import ReactPaginate from 'react-paginate'
import DatePicker from 'react-multi-date-picker'
import { DateObject } from 'react-multi-date-picker'
import moment from 'moment'; 
import admindata from '../../components/admindata'

const $ = require('jquery')
$.Datatable = require('datatables.net')

const DepositReport = () => {
  const [CompanyName, setCompanyName] = useState('')
  const [CompanyMobile, setCompanyMobile] = useState('')
  const [CompanyAddress, setCompanyAddress] = useState('')

  const [user, setUser] = useState()

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  useEffect(() => {
    const data = axios.get(baseUrl + 'settings/data', {}).then(res => {
      setCompanyName(res.data.CompanyName)
      setCompanyMobile(res.data.CompanyMobile)
      setCompanyAddress(res.data.CompanyAddress)
    })
  }, [])

  //use for pagination..
  let [limit, setLimit] = useState(10)

  const setpageLimit = event => {
    let key = event.target.value
    setLimit(key)
  }
  const [pageNumber, setPageNumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)


  const newDate = new Date();
  const DateSelected = moment(newDate).format("YYYY/MM/DD");

  const Year = new Date().getFullYear();
  const Month = new Date().getMonth() + 1; // Adding 1 to the month since getMonth() returns 0-11
  
  const formattedMonth = Month < 10 ? `0${Month}` : `${Month}`;
  
  const dynamicStartDate = `${Year}/${formattedMonth}/01`;
  const dynamicEndDate = `${Year}/${formattedMonth}/31`;
  
  const [startDate, setStartDate] = useState(dynamicStartDate);
  const [endDate, setEndDate] = useState(dynamicEndDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  //user for searching..
  // const [searchList, setSearchList] = useState(0);
  // const [searchType, setSearchType] = useState(0);

  //react paginate..
  const handlePageClick = async data => {
    let currentPage = data.selected + 1
    setPageNumber(currentPage)
    // scroll to the top
    //window.scrollTo(0, 0)
  }

  const profle = () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .get(
        baseUrl +
          `admin/get_txn/depositreports/all?page=${pageNumber}&_limit=${limit}&FROM_DATE=${startDate}&TO_DATE=${endDate}`,
        { headers }
      )
      .then(res => {
        setUser(res.data.datefind)
        setNumberOfPages(res.data.totalPages)
        //$('table').dataTable();
      })
  }

  const dateFormat = e => {
    const date = new Date(e)
    const newDate = date.toLocaleString('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
    return newDate
  }

  useEffect(() => {
    profle()
  }, [pageNumber, limit, startDate, endDate])

  if (user === undefined) {
    return null
  }

  const Print = () => {
    //console.log('print');
    let printContents = document.getElementById(`printablediv`).innerHTML
    let originalContents = document.body
    document.body.innerHTML = printContents
    window.print()
    document.body = originalContents
  }

  const createDownloadData = () => {
    handleExport()
  }
  const handleExport = () => {
    let table1 = [
      {
        A: 'Id',
        B: 'UserName',
        C: 'PhoneNumber',
        D: 'Deposit Amount',
        E: 'Deposit Status'
      }
    ]

    user.forEach(row => {
      const userDetails = row
      console.log('exldata', userDetails)
      table1.push({
        A: userDetails._id,
        B: userDetails.User_id ? userDetails.User_id.Name : '',
        C: userDetails.User_id ? userDetails.User_id.Phone : '',
        D: userDetails.amount ? userDetails.amount : '',
        E: userDetails.status ? userDetails.status : ''
      })
    })

    //table1 = [{A:"User Details"}].concat(table1);
    //const finalData = [...table1];
    //console.log(finalData);
    /* convert state to workbook */
    const ws = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true
    })
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'DepositReport.xlsx')
  }
  return (
    <div>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className='row '>
        <div className='col-12 grid-margin'>
          <div className='card mt-5'>
            <div className='card-body  table_bg'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='dashboard_heading'>Deposit Reports</h4>
                <div>
                  {admindata?.user?.usertype == 'Admin' ?
                   <>
                    <button
                    onClick={() => {
                      createDownloadData()
                    }}
                    className='btn btn-primary mr-2'
                  >
                    Export Data
                  </button>
                  </>
                  
                  :  '' }
                 
                  <button
                    type='button'
                    className='btn btn-success'
                    onClick={Print}
                  >
                    Print
                  </button>
                </div>
              </div>

              <div className='d-flex'>
                <select
                  className='form-control col-sm-1 mr-3'
                  style={{ height: '38px' }}
                  id='pagelimit'
                  name='pagelimit'
                  onChange={setpageLimit}
                >
                  <option value='10'>Set limit</option>
                  <option value='20'>20</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='500'>500</option>
                </select>
                <DatePicker
        selected={startDate}
        value={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        style={{ height: '40px' }}
      />
      {/* <span style={{color:"black"}}></span> */}
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        value={endDate}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="End Date"
        minDate={startDate} // Ensure that the end date cannot be before the start date
        style={{ height: '40px' }}
      />
              </div>
              {/* <div style={{ padding: 20 }}></div> */}
              <div className='table-responsive mt-3'>
                <div>
                  {user &&
                    user.map((data, key) => {
                      var id = data._id.toString(),
                        ctr = 0
                      var timestamp = id.slice(ctr, (ctr += 8))
                      var machineID = id.slice(ctr, (ctr += 6))
                      var processID = id.slice(ctr, (ctr += 4))
                      var counter = id.slice(ctr, (ctr += 6))
                      //console.log("id:", id);
                      //console.log("timestamp:", parseInt(timestamp, 16));
                      //console.log("machineID:", parseInt(machineID, 16));
                      //console.log("processID:", parseInt(processID, 16));
                      var counterid = parseInt(timestamp, 16)
                      return (
                        <div id='printablediv' className='desposte_report_main'>
                          <h1>Invoice</h1>

                          <div className='row'>
                            <div className='col-lg-8 col-sm-12'>
                              <div className='company_name'> {CompanyName}</div>
                              <div>
                                <b>Address:</b> {CompanyAddress}
                              </div>
                              <div>
                                <b> GSTIN :</b> 08AAPCM8651H1ZW
                              </div>
                              <div>
                                <b> MOB:</b> {CompanyMobile}
                              </div>
                            </div>

                            <div className='col-lg-4 col-sm-12'>
                              <table style={{ float: 'right' }}>
                                <tr>
                                  <th>
                                    Invoice Date :
                                    {dateFormat(data.createdAt).split(',')[0]}
                                  </th>

                                  <td></td>
                                </tr>
                              </table>
                            </div>
                          </div>

                          <Row style={{ marginTop: 48 }}>
                            <div>
                              <h6>
                                <b> User Name: </b>
                                {data.User_id && data.User_id.holder_name
                                  ? data.User_id.holder_name
                                  : data.User_id.Name}
                              </h6>
                              {admindata?.user?.usertype == 'Admin' ||
                              admindata?.user?.updateLegalData === true ? (
                                <h6>
                                  <b>Mobile No: </b>{' '}
                                  {data.User_id ? data.User_id.Phone : ''}
                                </h6>
                              ) : (
                                <h6>{`${
                                  '********' +
                                  data.User_id.Phone.toString().slice(-2)
                                }`}</h6>
                              )}
                            </div>
                          </Row>

                          <div className='row'>
                            <div className='col-12'>
                              <div className='table_responsive'>
                                <Table
                                  className='table'
                                  dataSource={[
                                    {
                                      id: 1,
                                      name: 'Deposit',
                                      price: `${
                                        data.amount ? 'Rs. ' + data.amount : ''
                                      }`,
                                      ID: `MSG-${counterid}`,
                                      TxnID: `${data.order_token}`,
                                      paymentType: `UPI`,
                                      paymentGatway: `${data.payment_gatway}`
                                    }
                                  ]}
                                  pagination={false}
                                >
                                  <Table.Column
                                    title='Description'
                                    dataIndex='name'
                                  />
                                  <Table.Column
                                    title='Amount'
                                    dataIndex='price'
                                  />
                                  <Table.Column
                                    title='OrderID'
                                    dataIndex='ID'
                                  />
                                  <Table.Column
                                    title='TxnID'
                                    dataIndex='TxnID'
                                  />
                                  <Table.Column
                                    title='PaymentType'
                                    dataIndex='paymentType'
                                  />
                                  <Table.Column
                                    title='PaymentGatway'
                                    dataIndex='paymentGatway'
                                  />
                                </Table>
                              </div>
                            </div>
                          </div>

                          <div className='row mt-3'>
                            <div className='col'>
                              <table>
                                <tr>
                                  <th> Total :</th>
                                  <td>
                                    {data.amount ? 'Rs. ' + data.amount : ''}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>

                          <div className='row mt-3'>
                            <div className='col-lg-12'>
                              <div>
                                <b> Account No:</b> 071263300004243
                              </div>
                              <div>
                                <b>IFSC Code:</b> YESB0000712
                              </div>
                              <div>
                                <b>Bank Name:</b> YES BANK
                              </div>
                            </div>
                          </div>

                          <div className='row mt-3'>
                            <div className='col-lg-12'>
                              ----*THIS IS A COMPUTER GENERATED INVOICE NO
                              SIGNATURE REQUIRED*----
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>

              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination justify-content-center'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DepositReport
