import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactSwitch from 'react-switch'
import axios from 'axios'
import Swal from 'sweetalert2'
import CryptoJS from "crypto-js";
import admindata from '../../components/admindata'


const Gateway = () => {


  
//////////URL//////////////
const [paynow1Url, SetPayNow1Url] = useState('')
const [paynow2Url, SetPayNow2Url] = useState('')
const [paynow3Url, SetPayNow3Url] = useState('')
const [paynow4Url, SetPayNow4Url] = useState('')
const [paynow5Url, SetPayNow5Url] = useState('')

const [KvmpayMin, SetPayMin] = useState(10)
const [KvmpayMax, SetPayMax] = useState(10)
const [upiMin, SetUpiMin] = useState(10)
const [upiMax, SetUpiMax] = useState(10)
const [acHName, SetAcHName] = useState(10)
const [acNo, SetAcNo] = useState(10)
const [acName, SetAcName] = useState(10)
const [ifsccode, SetIFSC] = useState(10)

  const [checkedRozorPayIn, setCheckedRozorPayIn] = useState(true)
  const [checkedRozorPay, setCheckedRozorPay] = useState(true)
  const [checkedHoadaPayIn, setCheckedHoadaPayIn] = useState(true)
  const [checkedHoadaPay, setCheckedHoadaPay] = useState(true)
  const [checkedMyPayIn, setCheckedMyPayIn] = useState(true)
  const [checkedUpigateway, setCheckedUpiGateway] = useState(true)
  const [checkedUpigatewayGk, setCheckedUpiGatewayGk] = useState(true)
  const [checkedKvmPayIn, setCheckedKvmPayIn] = useState(true)
  const [checkedAcount, setCheckedAccount] = useState(true)
  const [checkedMyPay, setCheckedMyPay] = useState(true)
  const [checkedPhonepeIn, setCheckedPhonepeIn] = useState(true)
  const [ispaytmWithdrawal, setIspaytmWithdrawal] = useState(true)
  const [RazorPayout, setRazorpayout] = useState(true)
  const [RazorDeposit, setRazorDeposit] = useState(true)
  const [RazorpayAuto, setRazorpayAuto] = useState(false)
  const [RazorPayKey, setRazorpayKey] = useState()
  const [RazorPaySecretKey, setRazorpaysecretKey] = useState()
  const [AccountName, setAccountName] = useState()
  const [decentroPayout, setdecentropayout] = useState(true)
  const [decentroDeposit, setdecentroDeposit] = useState(true)
  const [pinelabDeposit, setpinelabDeposit] = useState(true)
  const [decentroAuto, setdecentroAuto] = useState(false)
  const [settingId, setSettingId] = useState('')
  const [enable_otp, setEnableOtp] = useState()
  const [isCashFree, setIsCashFree] = useState(false)
  const [isPhonePayActive, setIsPhoneActive] = useState(false)
  const [PayNowOne, setPayNowOne] = useState(true)
  const [PayNowTwo, setPayNowTwo] = useState(true)
  const [PayNowThree, setPayNowThree] = useState(true)
  const [payNowFour, setPayNowFour] = useState(true)
  const [isPayNowFive, setPayNowFive] = useState(true)


  // images manual payment
const [payOneImage,setPayOneImage] = useState(null)
const [payTwoImage,setPayTwoImage] = useState(null)
const [payThreeImage,setPayThreeImage] = useState(null)
const [payFourImage,setPayFourImage] = useState(null)
const [payFiveImage,setPayFiveImage] = useState(null)

  // console.log(enable_otp)
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  useEffect(() => {

    const data = axios.get(baseUrl + 'gatewaysettings/data', {}).then(res => {
      console.log(res.data)
      setSettingId(res.data._id ? res.data._id : '')
      setRazorpayout(res.data.RazorPayout)
      setRazorDeposit(res.data.RazorDeposit)
      setRazorpayAuto(res.data.RazorpayAuto)
      setdecentropayout(res.data.decentroPayout)
      setdecentroDeposit(res.data.decentroDeposit)
      setdecentroAuto(res.data.decentroAuto)
      setRazorpayKey(res.data.RazorPayKey)
      setRazorpaysecretKey(res.data.RazorPaySecretKey)
      setAccountName(res.data.AccountName)
      setpinelabDeposit(res.data.pinelabDeposit)
      setEnableOtp(res.data.otp)
      setIsCashFree(res.data.isCashFreeActive)
      setIsPhoneActive(res.data.isPhonePayActive)
      setCheckedRozorPayIn(res.data.RazorPayout)
      setCheckedRozorPay(res.data.RazorDeposit)
      setCheckedHoadaPayIn(res.data.isHaodaPaypayInActive)
      setCheckedHoadaPay(res.data.isHaodaPayPayOutActive)
      setCheckedMyPayIn(res.data.isMypayInActive)
      setCheckedUpiGateway(res.data.upigateway)
      setCheckedUpiGatewayGk(res.data.upigatewayGk)
      setCheckedKvmPayIn(res.data.isKvmPayActive)
      setCheckedAccount(res.data.isAccountActive)
      setCheckedMyPay(res.data.isMypayOutActive)
      SetPayMin(res.data.KvmpayMin)
      SetPayMax(res.data.KvmpayMax)
      SetUpiMin(res.data.upiMin)
      SetUpiMax(res.data.upiMax)
      SetAcNo(res.data.acNo)
      SetAcName(res.data.acName)
      SetAcHName(res.data.acHName)
      SetIFSC(res.data.ifsccode)
      setCheckedPhonepeIn(res.data.isPhonePayActive)
      setIspaytmWithdrawal(res.data.ispaytmWithdrawal)
      setPayNowOne(res.data.isPayNowOne)
      setPayNowTwo(res.data.isPayNowTwo)
      setPayNowThree(res.data.isPayNowThree)
      setPayNowFour(res.data.isPayNowFour)
      setPayNowFive(res.data.isPayNowFive)
      setPayOneImage(res.data.isPayOneImage)
      setPayTwoImage(res.data.isPayTwoImage)
      setPayThreeImage(res.data.isPayThreeImage)
      setPayFourImage(res.data.isPayFourImage)
      setPayFiveImage(res.data.isPayFiveImage)
      SetPayNow1Url(res.data.paynow1Url)
      SetPayNow2Url(res.data.paynow2Url)
      SetPayNow3Url(res.data.paynow3Url)
      SetPayNow4Url(res.data.paynow4Url)
      SetPayNow5Url(res.data.paynow5Url)
    })
  }, [])

  const handleSubmit1 = async e => {
    e.preventDefault()
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    const response = await axios.post(baseUrl + `gatewaysettings`, {
       checkedRozorPayIn,
      checkedRozorPay,
      checkedHoadaPayIn,
       checkedHoadaPay,
       checkedMyPayIn,
       checkedUpigateway,
       checkedUpigatewayGk,
       checkedKvmPayIn,
       checkedMyPay,
       checkedAcount,
       PayNowOne,
       KvmpayMin,
       KvmpayMax,
       upiMin,
       upiMax,
       acNo,
       acName,
       acHName,
       ifsccode,
       PayNowTwo,
       PayNowThree,
       payNowFour,
       isPayNowFive,
       checkedPhonepeIn,
       ispaytmWithdrawal,
      settingId,
      RazorpayAuto,
      decentroPayout,
      decentroDeposit,
      decentroAuto,
      RazorPayKey,
      RazorPaySecretKey,
      AccountName,
      pinelabDeposit,
      otp: enable_otp,
      isCashFreeActive: isCashFree,
      payOneImage,
      payTwoImage,
      payThreeImage,
      payFourImage,
      payFiveImage,
      paynow1Url,
      paynow2Url,
      paynow3Url,
      paynow4Url,
      paynow5Url
    }, {headers})
    console.log(response.data.status)
    if (response.data.status === 'success') {
      alert('Settings submitted successfully')
    } else {
      alert('Settings Not Submitted')
    }
  }


  const handleChangeRozarpayIn = val => {
    setCheckedRozorPayIn(val)
  }
  const handleChangeRozarpay = val => {
    setCheckedRozorPay(val)
  }
  const handleChangehoadaPayIn = val => {
    setCheckedHoadaPayIn(val)
  }
  const handleChangehoadaPay = val => {
    setCheckedHoadaPay(val)
  }
  const handleChangeMyPayIn = val => {
    setCheckedMyPayIn(val)
  }
  const handleChangeUpi = val => {
    setCheckedUpiGateway(val)
  }
  const handleChangeUpiGk = val => {
    setCheckedUpiGatewayGk(val)
  }
  const handleChangeKvmPayIn = val => {
    setCheckedKvmPayIn(val)
  }
  const handleChangeaccount = val => {
    setCheckedAccount(val)
  }
  const handleChangeMyPay = val => {
    setCheckedMyPay(val)
  }
  const handleChangePhonepeIn = val => {
    setCheckedPhonepeIn(val)
  }
  const handleChangePaytm = val => {
    setIspaytmWithdrawal(val)
  }
  const handleChangePayOne = val => {
    setPayNowOne(val)
  }
  const handleChangePayTwo = val => {
    setPayNowTwo(val)
  }
  const handleChangePayThree = val => {
    setPayNowThree(val)
  }
  const handleChangePayFour = val => {
    setPayNowFour(val)
  }
  const handleChangePayFive = val => {
    setPayNowFive(val)
  }

  const uploadImage = (type)=>{
  if(!type){
    return false;
  }
  function logFormData(fd) {
    const entries = [];
    for (let pair of fd.entries()) {
      entries.push(`${pair[0]}: ${pair[1]}`);
    }
    return entries.join(', ');
  }
  
  // Function to generate HMAC
  const hmacGenerator = (message, key) => {
    try {
      var hash = CryptoJS.HmacSHA256(message, key);
      return CryptoJS.enc.Base64.stringify(hash);
    } catch (error) {
      console.error("Error generating HMAC:", error);
      return null;
    }
  };
  
  // Function to generate a unique secret key
  const generateSecretKey = () => {
    // Example: Use a random value or a timestamp
    return Math.random().toString(36).substring(2);
  };
  
  // Generate a secret key for HMAC
  const secretKey = generateSecretKey();
  console.log("Generated Secret Key:", secretKey);
  
  var formdata = new FormData();

    if(type == "PayNowOne"){
      formdata.append("status", PayNowOne);
      formdata.append("image", payOneImage);
      formdata.append("settingId", settingId);
      formdata.append("upi", paynow1Url);
    }else if(type == "PayNowTwo"){
      formdata.append("status", PayNowTwo);
      formdata.append("image", payTwoImage);
      formdata.append("settingId", settingId);
      formdata.append("upi", paynow2Url);
    }else if(type == "PayNowThree"){
      formdata.append("status", PayNowThree);
      formdata.append("image", payThreeImage);
      formdata.append("settingId", settingId);
      formdata.append("upi", paynow3Url);
    }else if(type == "PayNowFour"){
      formdata.append("status", payNowFour);
      formdata.append("image", payFourImage);
      formdata.append("settingId", settingId);
      formdata.append("upi", paynow4Url);
    }else{
      formdata.append("status", isPayNowFive);
      formdata.append("image", payFiveImage);
      formdata.append("settingId", settingId);
      formdata.append("upi", paynow5Url);
    }

formdata.append("image_type", type);


// Generate HMAC for the form data
const hmac = hmacGenerator(logFormData(formdata), secretKey);
formdata.append("hmac", hmac);

console.log("FormData after appending values:", logFormData(formdata));
console.log("Generated HMAC:", hmac);




var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(baseUrl+"gatewaysettings/change_qr", requestOptions)
  .then(response => {
    Swal.fire(
      'Success!',
      'Result Submit Successful',
      'success'
    )
    // window.location.reload(true)
  })
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }

  return (
    <>
      <div className='card mt-5'>
        <div className='card-body  table_bg'>
          <h4 className='dashboard_heading my-3'>Payment Gateway Settings</h4>

          {/* <h4 className='my-3 dashboard_small_heading_dark'>Pay In Or Pay Out</h4> */}
           

          {/* <h5 className='dashboard_small_heading_dark my-3'>RazorPay</h5> */}

          <form
            action='gatewaysettings'
            className='form'
            onSubmit={handleSubmit1}
            method='patch'
            enctype='multipart/form-date'
          >

<div className='form-row'>
            
            {/* <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      RozorPay
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>RozorPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedRozorPayIn}
                            onChange={handleChangeRozarpayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>RozorPay out</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedRozorPay}
                            onChange={handleChangeRozarpay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div> */}
            {/* <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      HoadaPay
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>HoadaPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedHoadaPayIn}
                            onChange={handleChangehoadaPayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>HoadaPay out</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedHoadaPay}
                            onChange={handleChangehoadaPay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div> */}
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      MyPay
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>MyPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedMyPayIn}
                            onChange={handleChangeMyPayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>MyPay out</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedMyPay}
                            onChange={handleChangeMyPay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Upi Gateway
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Gateway</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedUpigateway}
                            onChange={handleChangeUpi}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
            <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Upi Gk
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Gateway Gk</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedUpigatewayGk}
                            onChange={handleChangeUpiGk}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Upi Deposit Limit
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Min</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={upiMin}
                            onChange={e =>
                              SetUpiMin(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Upi Max</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='UpiMax'
                            value={upiMax}
                            onChange={e =>
                              SetUpiMax(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      KvmPay
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>KvmPay In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedKvmPayIn}
                            onChange={handleChangeKvmPayIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>KvmPay out</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedMyPay}
                            onChange={handleChangeMyPay}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Kvm Mini</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={KvmpayMin}
                            onChange={e =>
                              SetPayMin(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                      <Col sm={6}>Kvm Max</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={KvmpayMax}
                            onChange={e =>
                              SetPayMax(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
             

              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                     Account Deposit
                    </h4>
                    <Container>

                    <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Deposit</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedAcount}
                            onChange={handleChangeaccount}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Name</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={acName}
                            onChange={e =>
                              SetAcName(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Account No.'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Number</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='number'
                            name='ludokingroomcodeURL'
                            value={acNo}
                            onChange={e =>
                              SetAcNo(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Account No.'
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Account Holder Name</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='UpiMax'
                            value={acHName}
                            onChange={e =>
                              SetAcHName(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter Name'
                          />
                        </Col>
                      </Row>
                        <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>IFSC Code</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='UpiMax'
                            value={ifsccode}
                            onChange={e =>
                              SetIFSC(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter IFSC'
                          />
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>

              {/* <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Phonepe Deposit
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Phonepe In</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={checkedPhonepeIn}
                            onChange={handleChangePhonepeIn}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div> */}
              {/* <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      Paytm Withdrawal
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Paytm Withdrawal</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={ispaytmWithdrawal}
                            onChange={handleChangePaytm}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='submit' className='btn btn-dark'>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div> */}

       

            </div>

          

            {/* <h5 className='text-uppercase font-weight-bold my-3'>Pinelab</h5> */}
            <div className='form-row '>
              {/* <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2'>
                  Pinelab Deposit
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={pinelabDeposit}
                  onChange={e => setpinelabDeposit(e.target.value)}
                >
                  <option value='true'>Enable</option>
                  <option value='false'>Disable</option>
                </select>
              </div> */}
              <div className='form-group col-md-4'>
                <label htmlFor='buttondecdep' className=' mt-2'>
                  Enable Otp
                </label>
                <select
                  className='form-control '
                  name=''
                  id=''
                  value={enable_otp}
                  onChange={e => setEnableOtp(e.target.value)}
                >
                  <option value='1'>Enable</option>
                  <option value='0'>Disable</option>
                </select>
              </div>
          
             
            </div>

            <div className='form-row'>
              <div className='form-group col-md-4'>
                <button type='submit' className='btn btn-dark'>
                  submit
                </button>
              </div>
            </div>
          </form>
          {/* {  admindata?.user?.updateLegalData === true ? (
              <>   */}
          <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 1
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={PayNowOne}
                            onChange={handleChangePayOne}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI ID</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={paynow1Url}
                            onChange={e =>
                              SetPayNow1Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayOneImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>

                     
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payOneImage} target='_blank'>
                          <img src={baseUrl + payOneImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                          <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowOne')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
               {/* <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 2
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={PayNowTwo}
                            onChange={handleChangePayTwo}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI ID</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={paynow2Url}
                            onChange={e =>
                              SetPayNow2Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayTwoImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payTwoImage} target='_blank'>
                          <img src={baseUrl + payTwoImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}/>
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowTwo')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 3
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={PayNowThree}
                            onChange={handleChangePayThree}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI ID</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={paynow3Url}
                            onChange={e =>
                              SetPayNow3Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayThreeImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payThreeImage} target='_blank'>
                          <img src={baseUrl + payThreeImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowThree')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 4
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={payNowFour}
                            onChange={handleChangePayFour}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI ID</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={paynow4Url}
                            onChange={e =>
                              SetPayNow4Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayFourImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payFourImage} target='_blank'>
                          <img src={baseUrl + payFourImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('PayNowFour')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>
              <div className='form-group col-md-6'>
                <Card>
                  <Card.Body>
                    <h4 className='dashboard_small_heading_blue'>
                      PAY NOW 5
                    </h4>
                    <Container>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Enable</Col>
                        <Col sm={2}>
                          {' '}
                          <ReactSwitch
                            checked={isPayNowFive}
                            onChange={handleChangePayFive}
                            style={{ display: 'none' }}
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>UPI ID</Col>
                        <Col sm={1}>
                          {' '}
                          <input
                            type='text'
                            name='ludokingroomcodeURL'
                            value={paynow5Url}
                            onChange={e =>
                              SetPayNow5Url(e.target.value)
                            }
                            style={{
                              border: '1px solid black',
                              borderRadius: '3px'
                            }}
                            placeholder='Enter UPI'
                          />
                        </Col>
                      </Row>

                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>Image</Col>
                        <Col sm={1}>
                          <input
                            type='file'
                            accept='image/*'
                            name='rkludoPopular'
                            onChange={e =>
                              setPayFiveImage(e.target.files[0])
                            }
                            style={{ width: '200px' }}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}></Col>
                        <Col sm={4}>
                        <a href={baseUrl + payFiveImage} target='_blank'>
                          <img src={baseUrl + payFiveImage} style={{height:"50px", width:"50px"}}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://cdn1.vectorstock.com/i/1000x1000/94/00/selected-stamp-vector-9519400.jpg';
                          }}
                          />
                        </a>
                        </Col>
                        </Row>
                      <Row
                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                      >
                        <Col sm={6}>
                        <button type='button' className='btn btn-dark' onClick={e =>uploadImage('isPayNowFive')}>
                            submit
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </div>  */}
              {/* </>         
          ):""} */}
        </div>
      </div>
    </>
  )
}

export default Gateway
